<template>
	<div style="padding:20px 5px">
			<el-input placeholder="请输入报名链接" v-model="form.activityLink" style="width: 500px;"></el-input>
			<el-button type="primary" @click="submitForm">确 定</el-button>
	</div>
</template>

<script>
	export default {
		name: "activity",
		components: {
		},
		data() {
			return {
				form: {
					activityLink: '',
				},
			};
		},
		methods: {
			getTableDate: function() {
				var that = this;
				this.$axios
					.post(this.baseUrl + "admin/tender/activityGet",{})
					.then((response) => {
						console.log(response);
						that.form.activityLink = response.data;
					})
					.catch(function(error) {
						window.console.log(error);
					}); //ajax请求失败
			},
			submitForm() {
				var that = this;
				this.$axios
					.post(this.baseUrl + "admin/tender/activityUpd",that.form)
					.then((response) => {
						console.log(response);
						that.$alert(response.data.msg, "提示", {
							confirmButtonText: "确定",
						});
					})
					.catch(function(error) {
						window.console.log(error);
					}); //ajax请求失败
			},
		},
		mounted: function() {
			this.getTableDate("");
			//Object.assign(this.$refs.mychild.myValue);
			Object.assign(this.formInfo);
		},
	};
</script>

<style scoped>
	#searchForm .el-input {
		width: 217px;
	}
</style>
